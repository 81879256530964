import { computed } from 'vue';
import AccountRepository from '@/services/AccountRepository';

export default function useUserCrud(usingCloudStorage) {
  const useLocalStorage = computed(() => !usingCloudStorage.value);
  const accountObjSavedLocally = () => {
    const a = localStorage.getItem('account');

    if (a || a === 'local') {
      return false;
    }

    return true;
  };

  const createUser = async (user) => {
    const results = await AccountRepository.create(user);
    return results;
  };

  const getUser = async (idCode) => {
    if (useLocalStorage.value || !idCode) {
      return null;
    }
    const results = await AccountRepository.get(idCode);

    return results;
  };

  const deleteUser = async (idCode) => {
    let results;
    if (!useLocalStorage.value && idCode) {
      results = await AccountRepository.delete(idCode);
    }

    localStorage.removeItem('account');
    localStorage.removeItem('saveObject');

    return results;
  };

  const updateUser = async (idCode, payload) => {
    if (useLocalStorage.value || !idCode) {
      console.log('cannot save to cloud');
      return null;
    }
    const results = await AccountRepository.update(idCode, payload);

    return results;
  };

  const connectWithAnotherUser = (user) => AccountRepository.connect(user.idCode, user.partnerFriendCode);

  // const redeemLocalCoupon = (couponID, coupons) => {
  //   if (!coupons.forPartner) {
  //     return false;
  //   }

  //   const idx = coupons.findIndex((e) => e.id === couponID);

  //   if (idx > -1) {
  //     // eslint-disable-next-line no-param-reassign
  //     coupons[idx].redeemed = true;
  //     return true;
  //   }

  //   return false;
  // };

  // const redeemCoupon = async (user, partner, id) => {
  //   const results = useLocalStorage.value
  //     ? redeemLocalCoupon(partner.coupons || {}, id)
  //     : await AccountRepository.redeemCoupon(user?.idCode, partner?.idCode, id);

  //   return results;
  // };

  return {
    createUser,
    getUser,
    updateUser,
    deleteUser,
    accountObjSavedLocally,
    connectWithAnotherUser,
    // redeemCoupon,
  };
}
