/* eslint-disable max-len */
import routes from './routes';

export const home = {
  label: 'Home',
  path: '/',
};

const defaultTitle = 'Sexy Time is Fun';

const getSectionViews = (section) => routes.filter((e) => e.meta?.section === section)
  .map((e) => ({
    label: e.meta?.title || defaultTitle,
    path: e.path,
    description: e.meta?.description || null,
  }));

export const sexActs = {
  label: 'Customize',
  submenu: getSectionViews('discover'),
};

export const fun = {
  label: 'Fun and games',
  submenu: getSectionViews('fun'),
};

export const links = [
  home,
  sexActs,
  fun,
];

export default links;
