import { debounce } from 'debounce';

import {
  BModalConfig,
  BPromptDialogConfig,
  BDialogConfig,
  BSnackbarConfig,
} from 'buefy/types/components';

import {
  DialogProgrammatic as Dialog,
  ModalProgrammatic as Modal,
  SnackbarProgrammatic as Snackbar,
} from './buefy-notifications/useNotifications';

type BModalConfig = typeof BModalConfig
type BPromptDialogConfig = typeof BPromptDialogConfig;
type BDialogConfig = typeof BDialogConfig;
type BSnackbarConfig = typeof BSnackbarConfig;

export default function useNotifications() {
  let currentApiCalls = 0;

  const displayModal = (modalOpts: BModalConfig) => {
    Modal.open(modalOpts);
  };

  const displayDialogPrompt = (dialogOpts: BPromptDialogConfig) => {
    Dialog.prompt(dialogOpts);
  };

  const displayDialog = (dialogOpts: BDialogConfig) => {
    Dialog.confirm(dialogOpts);
  };

  const displaySnackbar = (snackbarOpts: BDialogConfig) => {
    const opts = {
      position: 'is-bottom',
      ...snackbarOpts,
    };
    Snackbar.open(opts as BSnackbarConfig);
  };

  const snackbarSuccess = (snackbarOpts: BSnackbarConfig) => {
    const args = {
      indefinite: false,
      type: 'is-success',
      position: 'is-bottom',
      ...snackbarOpts,
    } as BSnackbarConfig;
    displaySnackbar(args);
  };

  const errorHandler = (err: any) => {
    const snackbarOpts = {
      indefinite: false,
      type: 'is-danger',
      message: err.message,
      position: 'is-bottom',
    } as BSnackbarConfig;
    displaySnackbar(snackbarOpts);
  };

  /**
   * Debounced function for removing the API indicator.
   * Avoids the spinner "flashing" on the page as it is quickly removed and re-added for sequential API calls.
   */
  const removeApiIndicator = debounce(() => {
    if (currentApiCalls < 1) {
      const $elm = document.querySelector('#api-event-indicator');
      $elm?.remove();
    }
  }, 50);

  /**
 * Creates a window variable, currentApiCalls which contains a count of the current API calls.
 * while the API are move than 0 then we animate.
 */
  const displayApiIndicator = {
    createElement() {
      const $parentElm = document.createElement('div');
      $parentElm.setAttribute('id', 'api-event-indicator');
      $parentElm.classList.add('spinner');

      const $line = document.createElement('div');
      $line.classList.add('is-primary');

      $parentElm.append($line);
      $parentElm.append($line);
      $parentElm.append($line);

      document.body.append($parentElm);
    },

    startCall() {
      currentApiCalls = currentApiCalls ? currentApiCalls + 1 : 1;

      if (!document.querySelector('#api-event-indicator')) {
        this.createElement();
      }
    },

    endCall() {
      currentApiCalls -= 1;

      if (currentApiCalls < 1) {
        removeApiIndicator();
      }
    },
  };

  return {
    displayModal,
    displayDialogPrompt,
    displayDialog,
    displaySnackbar,
    errorHandler,
    snackbarSuccess,
    // notification spinner
    removeApiIndicator,
    displayApiIndicator,
  };
}
