/* eslint-disable max-len */
// import SEX_POSITONS from '@/lib/sex-positions';

export default {

  blowjob: {
    type: 'sex-act',
    id: 'blowjob',
    category: 'oral',
    tags: [
      'oral',
      'foreplay',
      'finish',
    ],
    link: [],
    details: null,
    title: 'Blowjob',
    canBeReciprocal: true,
    yourAction: {
      title: 'give your partner a blowjob',
      partnerNeeds: 'penis',
    },
    partnerAction: {
      title: 'let your partner give you a blowjob',
      youNeed: 'penis',
    },
  },

  tribbing: {
    id: 'tribbing',
    type: 'sex-act',
    category: 'manual stimulation',
    tags: [
      'grinding',
      'finish',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'rub your vagina against your parners vaginia',
      partnerNeeds: 'vagina',
      youNeed: 'vagina',
    },
    partnerAction: {
      title: 'let your partner rub their vagina against yours',
      partnerNeeds: 'vagina',
      youNeed: 'vagina',
    },
  },

  'yoni-massage': {
    type: 'sex-act',
    id: 'yoni-massage',
    category: 'manual stimulation',
    tags: [
      'mood',
      'hands',
      'foreplay',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'give your partner a yoni massage',
      partnerNeeds: 'vagina',
    },
    partnerAction: {
      title: 'let your partner give you a yoni massage',
      youNeed: 'vagina',
    },
  },

  'lingam-massage': {
    type: 'sex-act',
    id: 'lingam-massage',
    category: 'manual stimulation',
    tags: [
      'hands',
      'mood',
      'foreplay',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'give your partner a lingam massage',
      partnerNeeds: 'penis',
    },
    partnerAction: {
      title: 'let your partner give you a lingam massage',
      youNeed: 'penis',
    },
  },

  ahegao: {
    type: 'sex-act',
    id: 'ahegao',
    category: 'seduction',
    tags: [
      'mood',
      'atmosphere',
    ],
    title: 'ahegao face',
    yourAction: {
      title: 'show your partner your ahegao face',
    },
    partnerAction: {
      title: "watch your partner's ahegao face",
    },
  },

  'o-face': {
    type: 'sex-act',
    id: 'o-face',
    category: 'seduction',
    tags: [
      'mood',
      'atmosphere',
    ],
    title: 'O face',
    yourAction: {
      title: 'show your partner your "o" face',
    },
    partnerAction: {
      title: "watch your partner's \"o\" face",
    },
  },

  'share-porn-with-partner': {
    type: 'sex-act',
    id: 'share-porn-with-partner',
    title: 'share porn with partner',
    category: 'seduction',
    tags: [
      'voyerism',
      'foreplay',
      'mood',
      'atmosphere',
      'sharing',
    ],
    details: null,
    yourAction: {
      title: 'share porn of something you want to do with your partner',
    },
    partnerAction: {
      title: 'watch porn that your partner shares with you.',
    },
  },

  'make-partner-cum-via-blowjob': {
    type: 'sex-act',
    id: 'make-partner-cum-via-blowjob',
    category: 'oral',
    tags: [
      'oral',
      'foreplay',
      'finish',
    ],
    link: [],
    details: null,
    title: 'Blowjob',
    canBeReciprocal: true,
    yourAction: {
      title: 'make your partner cum by giving them blowjob',
      partnerNeeds: 'penis',
      youNeed: null,
    },
    partnerAction: {
      title: 'let your partner make you come via blowjob',
      youNeed: 'penis',
    },
  },

  'make-partner-cum-via-cunnilingus': {
    type: 'sex-act',
    id: 'make-partner-cum-via-cunnilingus',
    category: 'oral',
    tags: [
      'oral',
      'foreplay',
      'finish',
      'cum',
    ],
    link: [],
    details: null,
    title: 'cunnilingus',
    canBeReciprocal: true,
    yourAction: {
      title: 'make your partner cum by giving them cunnilingus',
      partnerNeeds: 'vagina',
      youNeed: null,
    },
    partnerAction: {
      title: 'let your partner make you come via cunnilingus',
      youNeed: 'vagina',
    },
  },

  'cum-in-vagina': {
    title: 'Cum in vagina',
    type: 'sex-act',
    id: 'cum-in-vagina',
    category: 'finish',
    tags: [
      'cum',
      'finish',
      'intercourse',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: "Cum in your partner's vagina",
      youNeed: 'penis',
      partnerNeeds: 'vagina',
    },
    partnerAction: {
      title: 'Let your partner cum in your vagina',
      youNeed: 'vagina',
      partnerNeeds: 'penis',
    },
    link: [],
    details: null,
  },

  'finger-sucking': {
    type: 'sex-act',
    id: 'finger-sucking',
    category: 'oral',
    tags: [
      'oral',
      'foreplay',
      'finish',
    ],
    link: [],
    details: null,
    title: 'Finger sucking',
    canBeReciprocal: true,
    yourAction: {
      title: "Suck on one or more of your partner's fingers",
    },
    partnerAction: {
      title: 'let  your partner suck on one or more of your fingers',
    },
  },

  spank: {
    type: 'sex-act',
    id: 'spank',
    category: 'fetish',
    tags: [
      'fetish',
      'foreplay',
    ],
    while: true,
    link: [],
    details: null,
    title: 'Spanking',
    canBeReciprocal: true,
    yourAction: {
      title: 'spank your partner',
    },
    partnerAction: {
      title: 'let  your partner to spank you',
    },
  },

  'grab-throat': {
    type: 'sex-act',
    id: 'grab-throat',
    category: 'extreme',
    tags: [
      'extreme',
      's&m',
      'kink',
    ],
    while: true,
    link: [],
    details: null,
    title: "Grab partner's throat",
    canBeReciprocal: true,
    yourAction: {
      title: "grab and lightly squeeze your partner's throat during sex",
    },
    partnerAction: {
      title: 'let  your partner to grab and lightly squeeze your throat during sex',
    },
  },

  choking: {
    type: 'sex-act',
    id: 'choking',
    category: 'extreme',
    tags: [
      'extreme',
      's&m',
      'kink',
    ],
    while: true,
    link: [],
    details: null,
    title: 'Choking',
    canBeReciprocal: true,
    yourAction: {
      title: 'choke your partner during sex',
    },
    partnerAction: {
      title: 'let  your partner to choke you during sex',
    },
  },

  slapping: {
    type: 'sex-act',
    id: 'slapping',
    category: 'extreme',
    tags: [
      'extreme',
      's&m',
      'kink',
    ],
    while: true,
    link: [],
    details: null,
    title: 'slapping',
    canBeReciprocal: true,
    yourAction: {
      title: 'slap your partner during sex',
    },
    partnerAction: {
      title: 'let  your partner slap you during sex',
    },
  },

  'use-degrading-words': {
    type: 'sex-act',
    id: 'use-degrading-words',
    category: 'language',
    tags: [
      'talk',
      'mood',
      'atmosphere',
    ],
    while: true,
    canBeReciprocal: true,
    yourAction: {
      title: 'call your partner degrading words (bitch, slut, whore, etc)',
    },
    partnerAction: {
      title: 'let your partner call you degrading words (bitch, slut, whore, etc)',
    },
    details: null,
    title: 'use degrading words (bitch, slut, whore, etc)',
  },

  'suck-semen-out-of-partners-vagina-or-anus-after-sex': {
    type: 'sex-act',
    id: 'suck-semen-out-of-partners-vagina-or-anus-after-sex',
    title: 'suck semen out of partners vagina or anus after sex',
    category: 'finish',
    tags: [
      'oral',
      'ejaculate',
      'semen',
      'finish',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: "suck semen out of partner's vagina or anus after sex",
      youNeed: 'penis',
      partnerNeeds: 'vagina',
    },
    partnerAction: {
      title: 'let your partner suck semen out of you vagina or anus after sex',
      partnerNeeds: 'penis',
      youNeed: 'vagina',
    },
    details: null,
  },

  'wear-latex-during-sex': {
    type: 'sex-act',
    id: 'wear-latex-during-sex',
    category: 'clothing',
    tags: [
      'costume',
      'attire',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'wear a latex suit',
    },
    partnerAction: {
      title: 'your partner wears the latex suit',
    },
    details: null,
  },

  'lapdance-someone-with-else': {
    type: 'sex-act',
    id: 'lapdance-someone-with-else',
    category: 'cuckolding',
    tags: [
      'exhibitionism',
      'exposure',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'give lap dance to someone else while your partner watches',
    },
    partnerAction: {
      title: 'get lap dance while your partner watches',
    },
    details: null,
  },

  'lapdance-with-your-partner': {
    type: 'sex-act',
    id: 'lapdance-with-your-partner',
    category: 'cuckolding',
    tags: [
      'exhibitionism',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'give lap dance to your partner',
    },
    partnerAction: {
      title: 'get lap dance from your partner',
    },
    details: null,
  },

  'flash-someone-else': {
    type: 'sex-act',
    id: 'flash-someone-else',
    category: 'dare',
    tags: [
      'exhibitionism',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'flash someone else in front of your partner',
    },
    partnerAction: {
      title: 'watch your partner flash someone else',
    },
    details: null,
  },

  'spit-on-face': {
    type: 'sex-act',
    id: 'spit-on-face',
    category: 'oral',
    tags: [
      'oral',
      'foreplay',
      'finish',
      'kink',
      'submission',
      'dominance',
    ],
    link: [],
    details: null,
    title: 'Spit on face',
    canBeReciprocal: true,
    yourAction: {
      title: 'Spit on your partner\'s face',
      youNeed: null,
    },
    partnerAction: {
      title: 'let your partner spit on you face',
    },
  },

  'flash-your-goods': {
    type: 'sex-act',
    id: 'flash-your-goods',
    category: 'voyerism',
    tags: [
      'exhibitionism',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'flash your partner',
    },
    partnerAction: {
      title: 'watch your partner flash you',
    },
    details: null,
  },

  'cuckolding-oral-sex': {
    type: 'sex-act',
    id: 'cuckolding-oral-sex',
    category: 'cuckolding',
    tags: [
      'exhibitionism',
      'oral',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'watch your partner perform oral sex on someone else',
    },
    partnerAction: {
      title: 'watch your partner have oral sex performed on him/her by someone else',
    },
    details: null,
  },

  bukkake: {
    type: 'sex-act',
    id: 'bukkake',
    category: 'group-sex',
    tags: [
      'group-sex',
      'cuckolding',
      'oral',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'perform bukkake on your partner with other males',
      youNeed: 'penis',
    },
    partnerAction: {
      title: "be bukkake'd by partner and other males",
      partnerNeeds: 'penis',
    },
    details: null,
  },

  'double-penetration': {
    type: 'sex-act',
    id: 'double-penetration',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'Penetrate your partner with another man',
      youNeed: 'penis',
      partnerNeeds: 'vagina',
    },
    partnerAction: {
      title: 'double penetration by your partner and another man',
      youNeed: 'vagina',
      partnerNeeds: 'penis',
    },
    details: null,
  },

  'triple-penetration': {
    type: 'sex-act',
    id: 'triple-penetration',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'penetrate your partner with two other males',
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'triple penetration by your partner two other males',
      youNeed: 'vagina',
      partnerNeeds: 'penis',
    },
    details: null,
  },

  'woken-up-by-oral-sex': {
    type: 'sex-act',
    id: 'woken-up-by-oral-sex',
    category: 'oral',
    tags: [
      'oral',
      'foreplay',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'wake up your partner with oral sex',
    },
    partnerAction: {
      title: 'get woken up by oral sex',
    },
    details: null,
  },

  'partner-masturbation': {
    type: 'sex-act',
    id: 'partner-masturbation',
    category: 'seduction',
    tags: [
      'voyerism',
      'foreplay',
      'teasing',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'masturbate for your partner',
    },
    partnerAction: {
      title: 'Watch your partner masturbate',
    },
    details: null,
  },

  'talk-dirty': {
    type: 'sex-act',
    id: 'talk-dirty',
    category: 'style',
    tags: [
      'talk',
      'mood',
      'atmosphere',
    ],
    while: true,
    canBeReciprocal: true,
    yourAction: {
      title: 'Talk dirty to your partner',
    },
    partnerAction: {
      title: 'let your partner to talk dirty',
    },
    details: null,
  },

  'spit-in-mouth': {
    type: 'sex-act',
    id: 'spit-in-mouth',
    category: 'oral',
    tags: [
      'oral',
      'foreplay',
      'finish',
      'kink',
      'submission',
      'dominance',
    ],
    link: [],
    details: null,
    title: 'Spit in mouth',
    canBeReciprocal: true,
    yourAction: {
      title: 'Spit into your partner\'s mouth',
      youNeed: null,
    },
    partnerAction: {
      title: 'let your partner spit into your mouth',
    },
  },

  'pearl-necklace': {
    type: 'sex-act',
    id: 'pearl-necklace',
    category: 'finish',
    tags: [
      'oral',
      'finish',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: "Cum on your partner's chest/neck",
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'Let your partner cum on your chest/neck',
      partnerNeeds: 'penis',
    },
    details: null,
  },

  facial: {
    type: 'sex-act',
    id: 'facial',
    category: 'finish',
    tags: [
      'finish',
      'oral',
      'submission',
      'dominance',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: "cum on your partner's face",
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'let your partner cum on your face',
      partnerNeeds: 'penis',
    },
    link: [],
    details: null,
    title: "cum on your partner's face",
  },

  'cum-in-ass': {
    type: 'sex-act',
    id: 'cum-in-ass',
    category: 'finish',
    tags: [
      'cum',
      'finish',
      'oral',
      'submission',
      'dominance',
      'anal-play',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: "Cum in your partner's ass",
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'Let your partner cum in your ass',
      partnerNeeds: 'penis',
    },
    details: null,
  },

  'cum-in-mouth': {
    type: 'sex-act',
    id: 'cum-in-mouth',
    category: 'finish',
    tags: [
      'cum',
      'finish',
      'oral',
      'submission',
      'dominance',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: "Cum in your partner's mouth",
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'Let your partner cum in your mouth',
      partnerNeeds: 'penis',
    },
    link: [],
    details: null,
  },

  'tell-partner-where-to-cum': {
    type: 'sex-act',
    id: 'tell-partner-where-to-cum',
    category: 'finish',
    tags: [
      'cum',
      'finish',
      'submission',
      'dominance',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'Cum where your partner tells you to',
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'tell your partner where to cum',
      partnerNeeds: 'penis',
    },
    link: [],
    details: null,
  },

  'cum-on-back': {
    type: 'sex-act',
    id: 'cum-on-back',
    category: 'finish',
    tags: [
      'cum',
      'finish',
      'submission',
      'dominance',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: "Cum on your partner's back",
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'Let your partner cum on your back',
      partnerNeeds: 'penis',
    },
    link: [],
    details: null,
  },

  'cum-on-stomach': {
    type: 'sex-act',
    id: 'cum-on-stomach',
    category: 'finish',
    tags: [
      'cum',
      'finish',
      'submission',
      'dominance',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: "Cum on your partner's stomach",
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'Let your partner cum on your stomach',
      partnerNeeds: 'penis',
    },
    link: [],
    details: null,
  },

  'finger-anus': {
    type: 'sex-act',
    id: 'finger-anus',
    category: 'anal-play',
    tags: [
      'anal-play',
      'hands',
      'finger',
      'foreplay',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: "Finger your partner's anus",
    },
    partnerAction: {
      title: 'Let your partner finger your anus',
    },
    details: null,
    title: 'Finger anus',
  },

  'anal-sex': {
    type: 'sex-act',
    id: 'anal-sex',
    category: 'anal-play',
    tags: [
      'anal-play',
      'penetration',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'Penetrate your partner anally',
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'Get penetrated by your partner anally',
      partnerNeeds: 'penis',
    },
    link: 'https://www.healthline.com/health/healthy-sex/anal-sex-safety',
    details: null,
    title: 'Anal sex',
  },

  'face-fucking': {
    type: 'sex-act',
    id: 'face-fucking',
    category: 'oral',
    tags: [
      'oral',
      'rough',
      'finish',
      'foreplay',
      'extreme',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'face/mouth fuck your partner',
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'let your partner fuck your face/mouth',
      partnerNeeds: 'penis',
    },
    link: 'https://slutlessons.wordpress.com/2011/08/16/throat-fucking/',
    details: null,
    title: 'Face/Mouth fucking',
  },

  'throat-fucking': {
    type: 'sex-act',
    id: 'throat-fucking',
    category: 'oral',
    tags: [
      'oral',
      'rough',
      'finish',
      'foreplay',
      'extreme',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'throat fuck your partner',
      youNeed: 'penis',
    },
    partnerAction: {
      title: 'let your partner fuck your throat',
      partnerNeeds: 'penis',
    },
    link: 'https://slutlessons.wordpress.com/2011/08/16/throat-fucking/',
    details: null,
    title: 'Throat fucking',
  },

  deepthroating: {
    type: 'sex-act',
    id: 'deepthroating',
    category: 'oral',
    tags: [
      'oral',
      'skilled',
      'fellatio',
      'finish',
      'foreplay',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'Let your partner deep throat your penis',
      details: "put your penis deep into your partner's mouth and throat",
      youNeed: 'penis',
    },
    partnerAction: {
      title: "deep throat your partner's penis",
      details: "Take your partner's penis deep into your mought and throat",
      partnerNeeds: 'penis',
    },
    link: 'https://slutlessons.wordpress.com/2011/08/10/deepthroating/',
    details: null,
    title: 'Deepthroating',
  },

  'leashes-and-collars': {
    type: 'sex-act',
    id: 'leashes-and-collars',
    category: 'clothing',
    tags: [
      'costume',
      'attire',
      'roleplay',
      'bdsm',
    ],
    link: 'https://www.quora.com/Have-you-ever-put-your-partner-on-a-leash-in-kinky-sex-How-did-that-play-out',
    details: null,
    yourAction: {
      title: 'Guide your partner around while they are wearing a leash and collar',
    },
    partnerAction: {
      title: 'Let your partner guide you around while you are wearing a leash and collar',
    },
    title: 'Leashes and collars',
  },

  'light-s&m': {
    type: 'sex-act',
    id: 'light-s&m',
    category: 'bdsm',
    tags: [
      'bdsm',
      'mood',
      'atmosphere',
    ],
    link: 'https://www.kinkly.com/definition/198/sadomasochism-sm',
    details: null,
    title: 'Light s&m',
    canBeReciprocal: true,
    yourAction: {
      title: 'perform some light S&M on your partner',
    },
    partnerAction: {
      title: 'let  your partner to perform light S&M on you',
    },
  },

  's&m': {
    type: 'sex-act',
    id: 's&m',
    category: 'bdsm',
    tags: [
      'bdsm',
      'mood',
      'atmosphere',
    ],
    link: 'https://www.kinkly.com/definition/198/sadomasochism-sm',
    details: null,
    title: 's&m',
    canBeReciprocal: true,
    yourAction: {
      title: 'perform some S&M on your partner',
    },
    partnerAction: {
      title: 'let  your partner to perform S&M on you',
    },
  },

  bondage: {
    type: 'sex-act',
    id: 'bondage',
    category: 'bdsm',
    tags: [
      'bdsm',
      'mood',
      'atmosphere',
    ],
    link: [],
    details: null,
    title: 'Bondage (light)',
    canBeReciprocal: true,
    yourAction: {
      title: 'have sex with your partner while they are tied up',
    },
    partnerAction: {
      title: 'have sex with your partner while you are tied up',
    },
  },

  'ass-play': {
    type: 'sex-act',
    id: 'ass-play',
    category: 'anal-play',
    tags: [
      'anal-play',
      'foreplay',
    ],
    link: 'https://www.kinkly.com/definition/10168/ass-play',
    details: null,
    title: 'Ass play',
    canBeReciprocal: true,
    yourAction: {
      title: 'play with your partners ass and asshole',
    },
    partnerAction: {
      title: 'let your partner play with your and asshole',
    },
  },

  'ass-licking': {
    type: 'sex-act',
    id: 'ass-licking',
    category: 'anal-play',
    tags: [
      'anal-play',
      'oral',
      'analingus',
      'foreplay',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: "Lick your partner's anus",
    },
    partnerAction: {
      title: 'Let your partner lick your anus',
    },
    link: [],
    details: null,
    title: 'Ass licking (rimming)',
  },

  'prostate-massage': {
    type: 'sex-act',
    id: 'prostate-massage',
    category: 'anal-play',
    tags: [
      'anal-play',
      'submission',
      'finish',
    ],
    link: 'https://www.google.com/url?sa=i&source=images&cd=&cad=rja&uact=8&ved=2ahUKEwjC68XjxbzhAhUjnOAKHYR1CRoQjxx6BAgBEAI&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DUxd0_1qNuyw&psig=AOvVaw022Ddg8yIRLVxniw7Kys1v&ust=1554677103551710',
    details: null,
    title: 'Prostate massage',
    canBeReciprocal: true,
    yourAction: {
      title: "give your partner's prostate a massage",
      partnerNeeds: 'penis',
    },
    partnerAction: {
      title: 'let your partner massage your prostate',
      youNeed: 'penis',
    },
  },

  'costume-stockings': {
    type: 'sex-act',
    id: 'costume-stockings',
    category: 'clothing',
    tags: [
      'costume',
      'attire',
    ],
    link: [],
    details: null,
    title: 'Costume - Stockings',
    yourAction: {
      title: 'wear sexy stockings for your partner',
    },
    partnerAction: {
      title: 'have your partner wear sexy stockings',
    },
  },

  'costume-police-officer': {
    type: 'sex-act',
    id: 'costume-police-officer',
    category: 'clothing',
    tags: [
      'costume',
      'attire',
    ],
    link: [],
    details: null,
    title: 'Costume - Police officer',
    yourAction: {
      title: 'dress up in a police officer costume for your partner',
    },
    partnerAction: {
      title: 'have your partner dress up as a police officer',
    },
  },

  'costume-nurse-doctor': {
    type: 'sex-act',
    id: 'costume-nurse-doctor',
    category: 'clothing',
    tags: [
      'costume',
      'attire',
    ],
    link: [],
    details: null,
    title: 'Costume - Nurse or doctor',
    yourAction: {
      title: 'dress up in a nurse or doctor costume for your partner',
    },
    partnerAction: {
      title: 'have your partner dress up as a nurse or doctor',
    },
  },

  'costume-cat': {
    type: 'sex-act',
    id: 'costume-cat',
    category: 'clothing',
    tags: [
      'costume',
      'attire',
    ],
    link: 'https://www.3wishes.com/sexy-costumes/animal-furry-costumes/cat-costumes/midnight-purr-black-cat-costume/',
    details: null,
    title: 'Costume - Cat',
    yourAction: {
      title: 'dress up in a cat costume for your partner',
    },
    partnerAction: {
      title: 'have your partner dress up as in a cat costume',
    },
  },

  handjob: {
    type: 'sex-act',
    id: 'handjob',
    category: 'manual stimulation',
    tags: [
      'hands',
      'foreplay',
    ],
    link: [],
    details: null,
    title: 'Handjob',
    canBeReciprocal: true,
    yourAction: {
      title: 'Give your partner a handjob',
      partnerNeeds: 'penis',
    },
    partnerAction: {
      title: 'let  your partner to give you a handjob',
      youNeed: 'penis',
    },
  },

  'vagina-worship': {
    type: 'sex-act',
    id: 'vagina-worship',
    category: 'oral',
    tags: [
      'oral',
      'servitude',
      'vagina',
      'foreplay',
    ],
    link: 'https://www.kinkly.com/definition/12536/pussy-worship',
    details: null,
    title: 'vagina worship',
    canBeReciprocal: true,
    yourAction: {
      title: "worship your partner's vagina",
      partnerNeeds: 'vagina',
    },
    partnerAction: {
      title: 'let  your partner to worship your vagina',
      youNeed: 'vagina',
    },
  },

  'penis-worship': {
    type: 'sex-act',
    id: 'penis-worship',
    category: 'oral',
    tags: [
      'oral',
      'servitude',
      'foreplay',
    ],
    link: 'https://www.kinkly.com/definition/12486/cock-worship',
    details: null,
    title: 'penis worship',
    canBeReciprocal: true,
    yourAction: {
      title: "worship your partner's penis",
      partnerNeeds: 'penis',
    },
    partnerAction: {
      title: 'let your partner worship your penis',
      youNeed: 'penis',
    },
  },

  'eat-vagina': {
    type: 'sex-act',
    id: 'eat-vagina',
    category: 'oral',
    tags: [
      'oral',
      'vagina',
      'foreplay',
    ],
    link: [],
    details: null,
    title: 'Eat vagina',
    yourAction: {
      title: "eat out your partner's vagina",
      partnerNeeds: 'vagina',
    },
    partnerAction: {
      title: 'let your partner eat out your vagina',
      youNeed: 'vagina',
    },
  },

  'finger-vagina': {
    type: 'sex-act',
    id: 'finger-vagina',
    category: 'manual stimulation',
    tags: [
      'hands',
      'vagina',
      'foreplay',
    ],
    link: [],
    details: null,
    title: 'finger vagina',
    yourAction: {
      title: "finger your partner's vagina",
      partnerNeeds: 'vagina',
    },
    partnerAction: {
      title: 'let your partner finger your vagina',
      youNeed: 'vagina',
    },
  },

  'sit-on-face': {
    type: 'sex-act',
    id: 'sit-on-face',
    category: 'oral',
    tags: [
      'oral',
      'vagina',
      'position',
      'foreplay',
    ],
    link: [],
    canBeReciprocal: true,
    yourAction: {
      title: "sit on your partner's face",
    },
    partnerAction: {
      title: 'let your partner sit on your face',
    },
    details: null,
    title: 'sit on face',
  },

  'mouth-gag': {
    type: 'sex-act',
    id: 'mouth-gag',
    category: 'toy',
    tags: [
      'extreme',
      'toys',
      'accessories',
      'attire',
    ],
    link: [],
    details: null,
    title: 'gag',
    variant: [
      'self',
    ],
    yourAction: {
      title: 'wear a gag in your mouth',
    },
    partnerAction: {
      title: 'have your partner wear a gag',
    },
  },

  'anal-plug': {
    type: 'sex-act',
    id: 'anal-plug',
    category: 'toy',
    tags: [
      'anal-play',
      'toys',
      'accessories',
      'attire',
    ],
    link: [],
    details: null,
    title: 'anal plug',
    variant: [
      'self',
    ],
    yourAction: {
      title: 'wear an anal plug',
    },
    partnerAction: {
      title: 'have your partner wear an anal plug',
    },
  },

  'wear-an-animal-tail': {
    type: 'sex-act',
    id: 'wear-an-animal-tail',
    category: 'accessories',
    tags: [
      'toys',
      'accessories',
      'attire',
    ],
    link: [],
    details: null,
    prefix: 'Would you like',
    yourAction: {
      title: 'to wear an animal tail',
    },
    partnerAction: {
      title: 'your partner wear an animal tail',
    },
    title: 'wear an animal tail',
  },

  'wear-a-remote-control-vibrator': {
    type: 'sex-act',
    id: 'wear-a-remote-control-vibrator',
    category: 'toy',
    tags: [
      'toys',
    ],
    link: 'https://www.lovense.com/bluetooth-remote-control-vibrator',
    details: null,
    title: 'wear a remote control vibrator',
    prefix: 'would you like',
    yourAction: {
      title: 'to wear a remote control vibrator',
    },
    partnerAction: {
      title: 'your partner to wear a remote control vibrator',
    },
  },

  'use-vibrator-on-clit-during-blowjob': {
    type: 'sex-act',
    id: 'use-vibrator-on-clit-during-blowjob',
    category: 'toy',
    tags: [
      'toys',
      'oral',
      'foreplay',
    ],
    link: [],
    details: null,
    prefix: 'would you like',
    title: 'use vibrator on clit during blowjob',
    yourAction: {
      title: 'to use vibrator on your clit while giving a blowjob',
      partnerNeeds: 'penis',
      youNeed: 'vagina',
    },
    partnerAction: {
      title: 'your partner to use vibrator on their clit while giving you a blowjob',
      partnerNeeds: 'vagina',
      youNeed: 'penis',
    },
  },

  'eat-ass-while-giving-handjob': {
    type: 'sex-act',
    id: 'eat-ass-while-giving-handjob',
    category: 'anal-play',
    tags: [
      'anal-play',
      'oral',
      'hands',
      'foreplay',
      'foreplay',
      'finish',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'Give your partner a rimjob while giving a handjob',
      partnerNeeds: 'penis',
    },
    partnerAction: {
      title: 'Let your partner give you rimjob and handjob',
      youNeed: 'penis',
    },
    link: [],
    details: null,
    title: 'eat ass while giving handjob',
  },

  'give-blowjob-and-prostate-massage': {
    type: 'sex-act',
    id: 'give-blowjob-and-prostate-massage',
    category: 'oral',
    tags: [
      'anal-play',
      'oral',
      'hands',
      'finish',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'Give your partner a blowjob while massaging their prostate',
      partnerNeeds: 'penis',
    },
    partnerAction: {
      title: 'Let your partner give you blowjob while massaging your prostate',
      youNeed: 'penis',
    },
    link: [],
    details: null,
    title: 'blowjob and prostate massage',
  },

  oming: {
    type: 'sex-act',
    id: 'oming',
    category: 'manual stimulation',
    tags: [
      'hands',
      'servitude',
      'foreplay',
      'finish',
    ],
    canBeReciprocal: true,
    yourAction: {
      title: 'OM your partner',
      partnerNeeds: 'vagina',
    },
    partnerAction: {
      title: 'Lay back, relax, and get OMd',
      youNeed: 'vagina',
    },
    link: 'https://en.wikipedia.org/wiki/OneTaste',
    details: null,
    title: 'OMing',
  },

  69: {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: '69',
    category: 'oral',
    tags: [
      'position',
      'oral',
      'foreplay',
    ],
    bgImg: 'https://thumbs.taxi69.com/taxi69_mobile/videos/7850/7850_panoramic.jpg',
    link: [],
    details: null,
    yourAction: {
      title: '69!',
    },
  },

  'have-sex-covered-in-oil': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'have-sex-covered-in-oil',
    category: 'fetish',
    tags: [
      'fetish',
      'atmosphere',
      'senses',
      'mood',
      'attire',
    ],
    details: null,
    yourAction: {
      title: 'Have sex covered in oil',
    },
    title: 'Have sex covered in oil',
  },

  'watch-other-couples-have-sex': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'watch-other-couples-have-sex',
    category: 'voyerism',
    tags: [
      'voyerism',
    ],
    details: null,
    yourAction: {
      title: 'watch other couples have sex (live)',
    },
  },

  'let-another-couple-watch-us-have-sex': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'let-another-couple-watch-us-have-sex',
    title: 'let another couple watch us have sex',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
    ],
    details: null,
    yourAction: {
      title: 'let another couple watch us have sex',
    },
  },

  'have-sex-monogamously-with-other-couples': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'have-sex-monogamously-with-other-couples',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
    ],
    details: null,
    yourAction: {
      title: "have sex monogamously with other couples (don't touch other couples)",
    },
  },

  'include-another-female-in-sex': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'include-another-female-in-sex',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    details: null,
    yourAction: {
      title: 'include another female in sex',
    },
  },

  'include-another-male-in-sex': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'include-another-male-in-sex',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    details: null,
    yourAction: {
      title: 'include another male in sex',
    },
  },

  'include-another-couple-in-sex': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'include-another-couple-in-sex',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    details: null,
    yourAction: {
      title: 'include another couple in sex (swinging, full-swap)',
    },
  },

  'include-more-than-two-males-in-sex': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'include-more-than-two-males-in-sex',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    details: null,
    yourAction: {
      title: 'include more than two males in sex',
    },
  },

  'include-more-than-two-females-in-sex': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'include-more-than-two-females-in-sex',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    details: null,
    yourAction: {
      title: 'include more than two females in sex',
    },
  },

  'participate-in-an-large-orgy': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'participate-in-an-large-orgy',
    category: 'group-sex',
    tags: [
      'group-sex',
      'orgy',
    ],
    details: null,
    yourAction: {
      title: 'participate in an large orgy (more than 4 people)',
    },
  },

  'have-another-person-take-video': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'have-another-person-take-video',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
      'photo',
      'video',
    ],
    details: null,
    yourAction: {
      title: 'have another person take video/photos while we have sex',
    },
  },

  'let-a-group-of-people-watch-us-have-sex': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'let-a-group-of-people-watch-us-have-sex',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
    ],
    details: null,
    yourAction: {
      title: 'let a group of people watch us have sex',
    },
  },

  'let-a-female-friend-watch-us-have-sex': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'let-a-female-friend-watch-us-have-sex',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
      'female',
    ],
    details: null,
    yourAction: {
      title: 'let a female friend watch us have sex',
    },
  },

  'let-a-male-friend-watch-us-have-sex': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'let-a-male-friend-watch-us-have-sex',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
      'male',
    ],
    details: null,
    yourAction: {
      title: 'let a male friend watch us have sex',
    },
  },

  'include-another-female-in-sex-where-only-the-females-interact': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'include-another-female-in-sex-where-only-the-females-interact',
    category: 'group-sex',
    tags: [
      'group-sex',
      'ffm',
    ],
    details: null,
    yourAction: {
      title: 'include another female in sex where only the females interact (FFM)',
    },
  },

  'include-another-male-in-sex-where-only-the-males-interact': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'include-another-male-in-sex-where-only-the-males-interact',
    category: 'group-sex',
    tags: [
      'group-sex',
      'mmf',
    ],
    details: null,
    yourAction: {
      title: 'include another male in sex where only the males interact (MMF)',
    },
  },

  'have-sex-in-front-of-a-mirror': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'have-sex-in-front-of-a-mirror',
    category: 'props',
    tags: [
      'voyerism',
      'props',
    ],
    details: null,
    yourAction: {
      title: 'Have sex in front of a mirror',
    },
  },

  'watch-porn-together': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'watch-porn-together',
    category: 'seduction',
    tags: [
      'voyerism',
      'foreplay',
      'mood',
      'atmosphere',
    ],
    details: null,
    yourAction: {
      title: 'Watch porn together',
    },
  },

  'use-sex-furniture': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'use-sex-furniture',
    category: 'props',
    tags: [
      'toys',
      'props',
    ],
    details: null,
    yourAction: {
      title: 'use sex furniture',
    },
  },

  'video-taping-acts': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'video-taping-acts',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
    ],
    bgImg: 'https://www.wrvo.org/sites/wrvo/files/styles/medium/public/201206/Video_Camera.jpg',
    link: [],
    details: null,
    yourAction: {
      title: 'video taping acts',
    },
  },

  'get-a-couples-erotic-massage': {
    type: 'sex-act',
    questionType: 'implied-consent',
    id: 'get-a-couples-erotic-massage',
    category: 'cuckolding',
    tags: [
      'group-sex',
      'eroticism',
      'foreplay',
      'mood',
      'atmosphere',
    ],
    bgImg: 'http://pragueforadults.com/wp-content/uploads/2015/03/eroticmassage.jpg',
    link: [],
    details: null,
    yourAction: {
      title: 'get a couples erotic massage',
    },
  },
};
