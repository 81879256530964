<template>
  <div
    class="modal has-text-centered is-active"
    :class="classes"
  >
    <div class="modal-background"></div>
    <div class="modal-content">
      <h6>{{ message }}</h6>
      <div class="loader-line"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {

    extraClasses: {
      type: String,
      default: null,
    },

    message: {
      type: String,
      default: '',
    },
  },

  computed: {
    classes() {
      return {
        extraClasses: this.extraClasses,
      };
    },
  },
});
</script>
