export default {
  birthday: null,
  name: null,
  idCode: null,
  sex: null,
  genitals: null,
  partnersGenitals: null,
  theme: 'purple-peach',
  wallpaper: '',
  partnerConnect: 'no',
  partnerFriendCode: null,
  connectedToPartner: false,
  list: {},
  coupons: {
    forPartner: [],
    fromPartner: [],
  },
  questionnaire: [],
};
