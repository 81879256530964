import axios from 'axios';
import useNotifications from '@/composables/useNotifications';

const { displayApiIndicator } = useNotifications();

const $api = axios.create(
  {
    withCredentials: false,
    baseURL: `${process.env.VUE_APP_API_URL}`,
    timeout: 20000,
    timeoutErrorMessage: 'timeout',
  },
);

$api.interceptors.request.use((requestConfigs) => {
  displayApiIndicator.startCall();

  return requestConfigs;
});

$api.interceptors.response.use(
  (response) => {
    displayApiIndicator.endCall();
    return response.data;
  },

  (error) => {
    displayApiIndicator.endCall();
    const { response } = error;
    if (!response) {
      return Promise.reject(error);
    }

    const { status } = response;
    if (error.code === 'ECONNABORTED') {
      return Promise.reject(error.response.data);
    }

    if (status >= 400) {
      // tio be used for session timeouts, and such
    }

    return Promise.reject(error.response.data);
  },
);

export default $api;
