import $api from './api';

export default {

  create(person) {
    const url = '/create';
    let data;

    if (person) {
      data = person;
    }

    return $api.post(url, data);
  },

  get(Key) {
    const url = '/get';

    const response = $api.post(url, { Key });
    return response;
  },

  delete(Key) {
    const url = '/delete';

    return $api.post(url, { Key });
  },

  update(Key, payload) {
    const url = '/update';

    const data = {
      Key,
      payload,
    };

    const response = $api.post(url, data);
    return response;
  },

  connect(Key, theirKey) {
    const url = '/connect';
    const opts = {
      Key,
      theirKey,
    };
    return $api.post(url, opts);
  },

  redeemCoupon(Key, theirKey, couponID) {
    const url = '/redeem-coupon';
    const opts = {
      Key,
      theirKey,
      couponID,
    };
    const response = $api.post(url, opts);
    return response;
  },
};
