import { createApp, h as createElement } from 'vue';

import Dialog from 'buefy/src/components/dialog/Dialog.vue';

import config from 'buefy/src/utils/config';
import { merge, getComponentFromVNode } from 'buefy/src/utils/helpers';
import { use, registerComponent, registerComponentProgrammatic } from 'buefy/src/utils/plugins';

function open(propsData) {
  let slot;
  if (Array.isArray(propsData.message)) {
    slot = propsData.message;
    // eslint-disable-next-line no-param-reassign
    delete propsData.message;
  }

  function createDialog(onConfirm, onCancel) {
    const container = document.createElement('div');
    const vueInstance = createApp({
      data() {
        return {
          dialogVNode: null,
        };
      },
      methods: {
        close() {
          const dialog = getComponentFromVNode(this.dialogVNode);
          if (dialog) {
            dialog.close();
          }
        },
      },
      render() {
        this.dialogVNode = createElement(
          Dialog,
          {
            ...propsData,
            // intentionally overrides propsData.onConfirm
            // to prevent propsData.onConfirm from receiving a "confirm" event
            onConfirm: (...args) => {
              if (onConfirm != null) {
                onConfirm(...args);
              }
            },
            // intentionally override propsData.onCancel
            // to prevent propsData.onCancel from receiving a "cancel" event
            onCancel: (...args) => {
              if (onCancel != null) {
                onCancel(...args);
              }
              vueInstance.unmount();
            },
            confirmCallback: (...args) => {
              if (propsData.onConfirm != null) {
                propsData.onConfirm(...args);
              }
            },
            cancelCallback: (...args) => {
              if (propsData.onCancel != null) {
                propsData.onCancel(...args);
              }
            },
          },
          slot ? { default: () => slot } : undefined,
        );
        return this.dialogVNode;
      },
    });
    return vueInstance.mount(container);
  }
  if (!config.defaultProgrammaticPromise) {
    return createDialog();
  }
  return new Promise((resolve) => {
    const dialog = createDialog(
      (event) => resolve({ result: event || true, dialog }),
      () => resolve({ result: false, dialog }),
    );
  });
}

const DialogProgrammatic = {
  alert(params) {
    const newParams = {};
    if (typeof params === 'string') {
      newParams.message = params;
    }

    const defaultParam = {
      canCancel: false,
    };
    const propsData = merge(defaultParam, newParams);
    return open(propsData);
  },
  confirm(params) {
    const defaultParam = {};
    const propsData = merge(defaultParam, params);
    return open(propsData);
  },
  prompt(params) {
    const defaultParam = {
      hasInput: true,
    };
    const propsData = merge(defaultParam, params);
    return open(propsData);
  },
};

const Plugin = {
  install(Vue) {
    registerComponent(Vue, Dialog);
    registerComponentProgrammatic(Vue, 'dialog', DialogProgrammatic);
  },
};

use(Plugin);

export default Plugin;

export {
  DialogProgrammatic,
  Dialog as BDialog,
};
