/* eslint-disable max-len */

export default [
  // home
  {
    path: '/',
    name: 'home',
    component: () => import(
      /* webpackChunkName: "home" */
      '../views/HomeView.vue'
    ),
    meta: {
      title: 'Sexy time is fun',
      // lobby,
    },
    props: true,
  },

  /**
   * The discovery section. This section contains the pages where users are exposed
   * to and rate sex acts and positions
   */

  // sex acts
  {
    name: 'sex-acts',
    path: '/sex-acts',
    component: () => import(
      /* webpackChunkName: "list" */
      '../views/discover/SexActsView.vue'
    ),
    meta: {
      title: 'Sex acts',
      section: 'discover',
      description: `View our list of over 100 sex acts to try.  Mark what you like,
      what you're willing to do with your partner - your answers
      will be used in our various games and activities.`,
    },
  },

  // matches
  {
    name: 'matches',
    path: '/matches',
    component: () => import(
      /* webpackChunkName: "matches" */
      '../views/discover/OurMatchesView.vue'
    ),
    meta: {
      title: 'Your matches',
      section: 'discover',
      description: `Have you and your partner checked out our sex positions and acts? See your matches here
      and find something you both want to do.`,
    },
  },

  // survey
  {
    name: 'questionnaire',
    path: '/questionnaire',
    component: () => import(
      /* webpackChunkName: "survey" */
      '../views/discover/QuestionnaireView.vue'
    ),
    meta: {
      title: 'Are you satisfied',
      section: 'discover',
      description: `Use this questionnaire to rate your sex life, indentify places for improvement,
      and to share you thoughts and needs with your partner.`,
    },
  },

  /**
   * The acitivities section is where we take the user rated things and use them
   * to in various ways
   */

  // the 2 minute game
  {
    name: '2minutegame',
    path: '/2-minute-game',
    component: () => import(
      /* webpackChunkName: "2-minute-game" */
      '../views/activities/2-minute-game.vue'
    ),
    meta: {
      title: 'The 2 Minute Game',
      section: 'fun',
    },
  },

  // shuffler
  {
    name: 'shuffler',
    path: '/shuffler',
    component: () => import(
      /* webpackChunkName: "shuffler" */
      '../views/activities/ShufflerView.vue'
    ),
    meta: {
      title: 'shuffler',
      section: 'fun',
    },
  },

  // date builder
  {
    name: 'date-builder',
    path: '/date-builder',
    component: () => import(
      /* webpackChunkName: "datebuilder" */
      '../views/activities/DateBuilderView.vue'
    ),
    meta: {
      title: 'date builder',
      section: 'fun',
    },
  },

  // sex-fighting
  {
    name: 'sexy-wrestling',
    path: '/wrestling',
    component: () => import(
      /* webpackChunkName: "sexfight" */
      '../views/activities/SexFight.vue'
    ),
    meta: {
      title: 'Sexy Wrestling',
      section: 'fun',
    },
  },

  // catch all
  {
    path: '/:pathMatch(.*)*',
    component: () => import(
      /* webpackChunkName: "404" */
      '../views/404View.vue'
    ),
  },
];
