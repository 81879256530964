<template>
  <div class="splash-screen-wrapper">
    <h1 class="title">Sexy Time Is Fun</h1>
    <div class="loader-line"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

.splash-screen-wrapper {

  &.is-fullscreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -3.25rem;
  }
}

.is-splash-screen {
  .modal-background {
    background: linear-gradient(134deg, var(--background-gradient)) !important;
  }

  .splash-screen-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image {
    max-width: 30vw;
  }

  .loader-line {
    background-color: white;
    border-radius: 1.25rem;
    height: 3px;
    margin: 1rem auto;
    overflow: hidden;
    position: relative;
    width: 200px;

    &::before {
      animation: lineAnim 1s linear infinite;
      background-color: var(--text-strong);
      border-radius: 1.25rem;
      content: "";
      height: 3px;
      left: -50%;
      width: 40%;
      position: absolute;
    }
  }
}
</style>
