import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
// import useNotifications from '@/composables/useNotifications';
import useUsersStore from '@/store/users';
import routes from './routes';
// import SplashScreenModal from '../components/SplashScreen/SplashScreenModal';

// const { errorHandler } = useNotifications();

const DEFAULT_TITLE = 'Sexy time is fun';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = to?.meta?.title ? `${to.meta.title}` : DEFAULT_TITLE;
  });
});

router.beforeEach((to, from, next) => {
  if (!to.meta.lobby) {
    next();
    return;
  }
  const usersStore = useUsersStore();
  if (usersStore.account) {
    next({ name: 'settings' });
  }
  next();
});

router.beforeEach((to, from, next) => {
  const $appMount = document.querySelector('#app-mount');

  const fromClass = `is-${from.name}-page`;
  const toClass = `is-${to.name}-page`;
  $appMount.classList.remove(fromClass);
  $appMount.classList.add(toClass);

  next();
});

router.beforeEach(async (to, from, next) => {
  const leaving = ['sex-acts', 'sex-positions', 'settings'];
  if (leaving.includes(from.name)) {
    const usersStore = useUsersStore();
    usersStore.currentUserString = 'user';
  }

  next();
});

export default router;
