<script setup>

import {
  computed,
  watch,
  toRefs,
} from 'vue';

import {
  useRoute,
} from 'vue-router';

// @ is an alias to /src
// ~ is an alias to /node_modules
import NavBar from '@@/NavBar/NavBar.vue';
import LoadingLine from '@@/LoadingLine/LoadingLine.vue';

import useUsersStore from '@/store/users';
import SplashScreen from '@@/SplashScreen/SplashScreen.vue';

import {
  links,
} from '@/router/nav-links';

// unpackage
const route = useRoute();
const usersStore = useUsersStore();

const {
  authenticate,
} = usersStore;

authenticate();
const isLobby = computed(() => route.meta?.lobby || false);

const {
  connectedToFriend,
  currentUserString,
  names,
  overlay,
  overlayClasses,
  user,
} = toRefs(usersStore);

// io

// computed
const currentPersonName = computed(() => {
  try {
    if (connectedToFriend.value) {
      return 'Your Settings';
    }
    let userName = '';

    if (currentUserString.value === 'user') {
      userName = names.value[currentUserString.value].toLowerCase() === 'you'
        ? 'Your'
        : names.value[currentUserString.value];
    } else {
      userName = names.value[currentUserString.value].toLowerCase() === 'your partner'
        ? 'Your partner\'s'
        : names.value[currentUserString.value];
    }

    return `${userName} Settings`;
  } catch (err) {
    return 'Settings';
  }
});

// methods
const {
  switchUser,
} = useUsersStore();

const setTheme = (themeClassName = 'purple') => {
  const $html = document.querySelector('html');
  $html.className = `is-${themeClassName}`;
};

const initTheme = async () => {
  setTheme();
};

watch(
  currentUserString,
  (newVal) => {
    const theme = newVal === 'user' ? 'purple' : 'blue-green';
    setTheme(theme);
  },
);

initTheme();

</script>

<style lang="scss">
  @import '@/_scss/entry.scss';
</style>

<template>
  <SplashScreen class="is-fullscreen" v-if="!user && !isLobby" />
  <div
    v-else
    id="app"
  >
    <loading-line
      v-if="overlay"
      :message="overlay"
      :extraClasses="overlayClasses"
    />

    <NavBar
      :links="links"
      :connectedToFriend="connectedToFriend"
      :currentPersonName="currentPersonName"
      @switchUser="switchUser"
    />
    <router-view />
  </div>
</template>
