import cloneThis from '@/lib/cloneThis';
import GLOBAL_LIST from '@/lib/list';
import UserTemplate from '@/lib/person-template';

// the category list-  should rename
const categories = Object.values(GLOBAL_LIST).reduce(
  (
    collector: string[],
    e: any,
  ) => {
    if (e.category !== 'sex-position') {
      collector.push(e.category);
    }

    return collector;
  },
  [] as string[],
);

export const actList = [...new Set(categories)];

export const PartnerTemplate = cloneThis(UserTemplate);

PartnerTemplate.theme = 'blue-green';
PartnerTemplate.wallpaper = 'scales';

export interface UserPayload {
  user: UserInfo,
  partner?: UserInfo | null
}

export const buildUpdatePayload = (user: UserInfo, connectedToFriend: boolean | false, partner: UserInfo | null) => {
  const payload = {
    user,
  } as UserPayload;

  if (!connectedToFriend) {
    payload.partner = partner;
  }

  return payload;
};

export function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object'
    && error !== null
    && 'message' in error
    && typeof (error as Record<string, unknown>).message === 'string'
  );
}

export function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}

export default {
  actList,
  PartnerTemplate,
  buildUpdatePayload,
  isErrorWithMessage,
  toErrorWithMessage,
  getErrorMessage,
};
