import {
  ref,
  Ref,
  computed,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useStorage } from '@vueuse/core';

import UserTemplate from '@/lib/person-template';
import cloneThis from '@/lib/cloneThis';
import userUserCrud from '@/composables/useUserCrud';
import useNotifications from '@/composables/useNotifications';
import {
  PartnerTemplate,
  buildUpdatePayload,
  getErrorMessage,
} from '@/lib/pinia-lib';

const {
  displayDialog,
  errorHandler,
  displaySnackbar,
} = useNotifications();

export default defineStore(
  'users',
  () => {
    const router = useRouter();

    const account = useStorage('account', null as string | null);
    const saveObj = useStorage('saveObj', {
      user: cloneThis(UserTemplate),
      partner: cloneThis(PartnerTemplate),
    });

    const usingCloudStorage = computed(() => !!account.value);
    const {
      createUser,
      getUser,
      updateUser,
      connectWithAnotherUser,
    } = userUserCrud(usingCloudStorage);

    const user = computed({
      get() {
        return saveObj.value.user || cloneThis(UserTemplate);
      },
      set(newVal) {
        saveObj.value.user = newVal;
      },
    });

    const partner = computed({
      get() {
        return saveObj.value.partner || cloneThis(PartnerTemplate);
      },
      set(newVal) {
        saveObj.value.partner = newVal;
      },
    });

    const overlay = ref<string | boolean>(false);
    const overlayClasses = ref<string | null>(null);
    const currentUserString = ref<personTargetString>('user');

    // computed
    const getTargetUser = (target: personTargetString): Ref<UserInfo | null> => (target === 'user' ? user : partner);
    const getOppositeUser = (target: personTargetString): Ref<UserInfo | null> => (target === 'user' ? partner : user);

    const currentUser = computed(() => getTargetUser(currentUserString.value).value);
    const currentPartner = computed(() => getOppositeUser(currentUserString.value).value);

    const accountReady = computed(() => !!account.value);

    const names = computed(() => ({
      user: user.value.name || 'Person 1',
      partner: partner.value?.name || 'Person 2',
    }));

    const connectedToFriend = computed(() => {
      if (!user.value) {
        return false;
      }

      return user.value.partnerConnect === 'yes'
        && user.value.partnerFriendCode
        && partner.value?.partnerConnect === 'yes'
        && partner.value?.idCode === user.value.partnerFriendCode;
    });

    const hasSettings = computed(() => {
      if (!user.value) {
        return false;
      }
      const hasGenitals = user.value.genitals && partner.value?.genitals;
      const hasNames = user.value.name && partner.value?.name;

      if (hasGenitals && hasNames) return true;
      if (user.value.genitals) return true;
      return false;
    });

    const usingLocalPartner = computed(() => {
      if (!user.value) {
        return false;
      }
      return user.value.partnerConnect === 'no';
    });

    // methods
    const getPersonName = (target: personTargetString): nameResponse => getTargetUser(target).value?.name;

    const displayUserSwitchNotification = () => {
      const suffix = currentUserString.value === 'partner' ? '\'s partner' : '';
      const args = {
        message: `Using site as the device owner${suffix}`,
        actionText: null,
      };

      displaySnackbar(args);
    };

    const switchUser = () => {
      currentUserString.value = currentUserString.value === 'user' ? 'partner' : 'user';

      displayUserSwitchNotification();
    };

    const setAccountObj = (payload?: string) => {
      account.value = payload;
    };

    const setOverlay = (value: boolean | string) => {
      overlay.value = value;
      overlayClasses.value = 'is-super-extra-mega-large';
    };

    const resetUser = () => {
      user.value = cloneThis(UserTemplate);
    };

    const resetPartner = () => {
      partner.value = cloneThis(PartnerTemplate);
    };

    const resetEverything = () => {
      resetUser();
      resetPartner();
      account.value = null;
    };

    const couldNotRetrieveAccount = (idCode?: string) => {
      const dialogOpts = {
        title: 'Warning',
        message: `We couldn't find that account (${idCode})`,
        cancelText: 'Reload page and try again',
        confirmText: 'Change your account',
        type: 'is-danger',
        onConfirm: async () => {
          setAccountObj();
          await router.push({ name: 'login' });
        },
        onCancel: () => {
          window.location.reload();
        },
      };
      displayDialog(dialogOpts);
    };

    const loadUsersFromDBPayload = (payload: saveInfo) => {
      const targets = ['user', 'partner'];
      targets.forEach((target) => {
        const userObj = getTargetUser(target);
        const defaultTemplate = target === 'user'
          ? cloneThis(UserTemplate)
          : cloneThis(PartnerTemplate);
        const saveObjTmp = target === 'user' ? payload.user : payload.partner;
        userObj.value = saveObjTmp || defaultTemplate;
      });
    };

    const logout = async () => {
      resetEverything();
      await router.push({ name: 'login' });
    };

    const fetchAccountErrorHandler = (err: any, idCode?: string) => {
      if (err.code === 'ERR_NETWORK') {
        const dialogOpts = {
          title: 'Network Error',
          message: `<div class="content">
              <p>There was a network error getting you're account.
              Please try again later.</p>
            </div>`,
          confirmText: 'Close',
          type: 'is-danger',
          onConfirm: () => {
            console.log('canceled');
          },
        };
        displayDialog(dialogOpts);
      } else if (err.code === 404) {
        couldNotRetrieveAccount(idCode);
      } else {
        errorHandler(err);
      }
    };

    const fetchAccount = async (idCode?: string) => {
      if (!idCode) return;
      try {
        account.value = idCode;
        const results = await getUser(idCode);
        loadUsersFromDBPayload(results);
      } catch (err) {
        fetchAccountErrorHandler(err, idCode);
      }
    };

    const login = async (idCode: string) => {
      setOverlay('Sexy Time is Fun');
      try {
        await fetchAccount(idCode);
        await router.push({ name: 'settings' });
        return true;
      } catch (err: any) {
        fetchAccountErrorHandler(err, idCode);

        return false;
      } finally {
        setOverlay(false);
      }
    };

    const updateAccount = async () => {
      if (!user.value || !account.value) {
        return;
      }

      const idCode = account.value;

      const payload = {
        user: user.value,
      } as any;

      if (user.value?.partnerConnect === 'no' || !user.value?.partnerConnect) {
        payload.partner = partner.value;
      }

      console.log('updating account');
      try {
        const result = await updateUser(idCode, payload);

        if (result?.partner) {
          partner.value = result.partner;
        }
      } catch (err) {
        errorHandler(err);
      }
    };

    const createNewAccount = async (silent = false) => {
      if (account.value) {
        const err = new Error('User already exists on this device');
        if (!silent) {
          errorHandler(err);
        }
        return false;
      }

      if (!user.value) {
        user.value = cloneThis(UserTemplate);
      }

      const payload = buildUpdatePayload(
        user.value,
        user.value?.connectedToPartner || false,
        partner.value || null,
      );

      try {
        setOverlay('Creating new account');
        const response = await createUser(payload);

        if (typeof response?.idCode !== 'string') {
          throw Error('Create failed, no ID code.');
        }

        const { idCode } = response;
        user.value.idCode = idCode;
        setAccountObj(idCode);

        return true;
      } catch (err) {
        setAccountObj();
        errorHandler(err);
        return false;
      } finally {
        setOverlay(false);
      }
    };

    const setPartnerData = (payload: UserInfo) => {
      partner.value = payload;
      if (partner.value.birthday) {
        partner.value.birthday = new Date(partner.value.birthday);
      }
    };

    const connectWithFriend = async () => {
      if (!user.value) {
        return false;
      }

      try {
        const response = await connectWithAnotherUser(user.value.idCode, user.value.partnerFriendCode);
        setPartnerData(response);

        return response;
      } catch (error: any) {
        if (error.message.indexOf('does not exist.') > -1) {
          user.value.partnerFriendCode = null;
          user.value.connectedToPartner = false;
          user.value.partnerConnect = 'no';
        }
        getErrorMessage(error);
        errorHandler(error);
        return false;
      }
    };

    const connectWithFriendSilent = async () => {
      if (!user.value) {
        return false;
      }

      try {
        const response = await connectWithAnotherUser(user.value.idCode, user.value.partnerFriendCode);
        setPartnerData(response);

        return response;
      } catch (error) {
        return false;
      }
    };

    const checkForAccount = () => account.value || false;

    const changeUser = (payload: personTargetString) => {
      if (!['user', 'partner'].includes(payload)) return;
      currentUserString.value = payload;

      displayUserSwitchNotification();
    };

    const changeToUser = () => {
      currentUserString.value = 'user';

      displayUserSwitchNotification();
    };

    const changeToPartner = () => {
      currentUserString.value = 'partner';

      displayUserSwitchNotification();
    };

    const getOppositePersonString = (target: personTargetString) => (target === 'user' ? 'partner' : 'user');
    const oppositePersonString = computed(() => getOppositePersonString(currentUserString.value));

    const authenticate = async () => {
      const idCode = checkForAccount() || undefined;
      if (idCode) {
        fetchAccount(idCode);
      }
    };

    // partner connect
    const partnerConnectInterval = ref<number | undefined>();
    const hasPartnerCode = computed(() => !!user.value?.partnerFriendCode);
    const partnerConnectSettingTrue = computed(() => user.value?.partnerConnect === 'yes');

    const waitingForPartnerConnection = computed(() => !!partnerConnectSettingTrue.value
      && !!hasPartnerCode.value && !connectedToFriend.value);

    const partnerConnectMessage = computed(() => {
      const notWaitingForPartnerConnection = !waitingForPartnerConnection.value && connectedToFriend.value
        ? 'connected'
        : '';

      return waitingForPartnerConnection.value
        ? 'Waiting for partner approval...'
        : notWaitingForPartnerConnection;
    });

    const setPartnerConnectInterval = () => {
      partnerConnectInterval.value = setInterval(async () => {
        await connectWithFriendSilent();
      }, 10000);
    };

    watch(
      waitingForPartnerConnection,
      (newVal) => {
        if (newVal === false) {
          clearInterval(partnerConnectInterval.value);
        } else {
          setPartnerConnectInterval();
        }
      },
    );

    watch(
      () => saveObj.value,
      (newval, oldval) => {
        if (!oldval) return;
        updateAccount();
      },
      {
        immediate: true,
        deep: true,
      },
    );

    return {
      // data
      account,
      accountReady,
      currentUserString,
      oppositePersonString,
      user,
      partner,
      currentUser,
      currentPartner,
      names,
      connectedToFriend,
      hasSettings,
      usingLocalPartner,
      // methods
      switchUser,
      getTargetUser,
      getOppositeUser,
      login,
      logout,
      fetchAccount,
      createNewAccount,
      resetPartner,
      connectWithFriend,
      connectWithFriendSilent,
      checkForAccount,
      getPersonName,
      changeUser,
      changeToUser,
      changeToPartner,
      authenticate,
      // partner connect
      setPartnerConnectInterval,
      partnerConnectMessage,
      waitingForPartnerConnection,
      partnerConnectSettingTrue,
      hasPartnerCode,
      partnerConnectInterval,
    };
  },
);
